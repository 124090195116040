body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
  margin: auto;
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
}

#thq-main-body {
  /* padding-left: 2rem !important; */
  padding-top: 4.25rem !important;
  padding-bottom: 1.25rem !important;
  margin: auto;
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
}

#thq-sidebar-wrapper {
  min-height: 100vh;
  margin-left: -12rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#thq-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper .list-group {
  width: 12rem;
}

#thq-content-wrapper {
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
  min-width: 100vw;
}

#thq-main-wrapper.toggled #thq-sidebar-wrapper {
  margin-left: 0;
}

/* @media (min-width: 768px) {
  #thq-sidebar-wrapper {
    margin-left: 0;
  }

  #thq-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #thq-main-wrapper.toggled #thq-sidebar-wrapper {
    margin-left: -12rem;
  }
} */

#thq-sidebar-wrapper #thq-sidebar-modules {
  /* padding: 1.25rem 1.25rem; */
  font-size: 1.2rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules p {
  font-size: 1rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules a {
  color: #999999;
  
}

#thq-main-wrapper .border-right {
  border-right: 1px solid #efefef !important;
}

.navbar-brand {
  display: inline !important;
}
.toggled .navbar-brand {
  display: none !important;
}
