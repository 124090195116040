body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
  margin: auto;
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
}

#thq-main-body {
  /* padding-left: 2rem !important; */
  padding-top: 4.25rem !important;
  padding-bottom: 1.25rem !important;
  margin: auto;
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
}

#thq-sidebar-wrapper {
  min-height: 100vh;
  margin-left: -12rem;
  -webkit-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#thq-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#thq-sidebar-wrapper .list-group {
  width: 12rem;
}

#thq-content-wrapper {
  /* border-style:dashed;
  border-width: 1px;
  border-color: red; */
  min-width: 100vw;
}

#thq-main-wrapper.toggled #thq-sidebar-wrapper {
  margin-left: 0;
}

/* @media (min-width: 768px) {
  #thq-sidebar-wrapper {
    margin-left: 0;
  }

  #thq-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #thq-main-wrapper.toggled #thq-sidebar-wrapper {
    margin-left: -12rem;
  }
} */

#thq-sidebar-wrapper #thq-sidebar-modules {
  /* padding: 1.25rem 1.25rem; */
  font-size: 1.2rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules p {
  font-size: 1rem;
}

#thq-sidebar-wrapper #thq-sidebar-modules a {
  color: #999999;
  
}

#thq-main-wrapper .border-right {
  border-right: 1px solid #efefef !important;
}

.navbar-brand {
  display: inline !important;
}
.toggled .navbar-brand {
  display: none !important;
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url("/assets/westfield/fonts/proxima/ProximaNova-Regular.otf")
    format("opentype");
}
* {
  font-family: "ProximaNovaRegular" !important;
}
.atom-alert {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .alert-light {
  color: transparent !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #fff9fa !important;
  border-color: #fff9fa !important;
} */

.is-invalid {
  border-color: #ef9a9a !important;
  background-image: none !important;
}

.is-invalid:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.05rem #c3e0ff !important;
}

.hidden {
  display: none;
}

button a {
  color: #ffffff !important;
}

button {
  /* margin-right: 5px !important; */
}

/* recaptcha */
#g-recaptcha {
  margin: 10px auto;
  text-align: center;
}
#g-recaptcha > div {
  width: 100% !important;
}

/* className="productSelect" */
.productSelect {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.productSelect:hover {
  background: #f44336;
}
.productSelect-active-hb,
.productSelect-active-ty,
.productSelect-active-df {
  background: #f44336;
}
.accepting-retailer-link:hover {
  /* background-color: black !important; */
  border-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: red;
  border-style: solid;
}

/* banner wrapper */
.heroWrap-loader,
.bannerWrap-loader {
  position: relative;
  background-color: #efefef;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerBlack h1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #000;
  z-index: 10;
  max-width: 70%;
}
.banner h1 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #fff;
  z-index: 10;
  max-width: 70%;
}
section.banner,
section.banner h1 {
  margin: 0 auto;
  width: 100%;
}
.heroWrap,
.bannerWrap,
section.banner {
  min-height: 366px;
}

.heroWrapHome {
  min-height: 400px;
}
.heroWrapHome {
  position: relative;
  /* background-color: rgb(0, 0, 0); */
  /* background-color: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(121, 9, 9, 1) 13%
  ); */
  /* background-image: linear-gradient(blue, pink); */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.heroWrap,
.bannerWrap {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerWrap:after {
  content: "";
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.hero h1 {
  width: 38%;
  padding-top: 6rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  color: black;
}
.hero h3,
.hero h6 {
  width: 38%;
  padding-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: black;
}
.hero .btnWrap {
  width: 38%;
}
section.hero {
  margin: 0 auto;
  width: 100%;
}
section.size1 h1 {
  font-size: 48px !important;
}
section.size2 h1 {
  font-size: 40px !important;
}
section.size3 h1 {
  font-size: 32px !important;
}
section.size4 h1 {
  font-size: 52px !important;
}
section.size5 h1 {
  font-size: 44px !important;
}
section.size6 h1 {
  font-size: 36px !important;
}
section.size1 h6 {
  font-size: 24px !important;
}
section.size2 h6 {
  font-size: 20px !important;
}
section.size3 h6 {
  font-size: 16px !important;
}
section.size4 h6 {
  font-size: 28px !important;
}
.btnWrap {
  padding: 1rem 0;
  text-align: center;
}

.skeleton-container {
  width: 300px;
}
.skeleton-container .xs {
  width: 10%;
}
.skeleton-container .sm {
  width: 15%;
}
.skeleton-container .md {
  width: 30%;
}
.skeleton-container .lg {
  width: 60%;
}
.skeleton-container .xl {
  width: 100%;
}
.skeleton-container-wide {
  width: 100%;
  margin: auto;
}
.skeleton-container-wide .xl {
  width: 99.2%;
}
.h10 {
  height: 10px;
}
.h15 {
  height: 15px;
}
.h30 {
  height: 30px;
}
.h60 {
  height: 60px;
}
.h120 {
  height: 120px;
}
.h336 {
  height: 355px;
}
.skeleton-container .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: linear-gradient(
    90deg,
    #f9f9f9 0px,
    #f4f4f4 40px,
    #f9f9f9 80px
  );
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container .clear {
  clear: left;
}

.skeleton-container-wide .line {
  float: left;
  margin: 5px;
  border-radius: 8px;
  background-image: linear-gradient(
    90deg,
    #f9f9f9 0px,
    #f4f4f4 40px,
    #f9f9f9 80px
  );
  background-size: 600px;
  -webkit-animation: shine-lines 3s infinite ease-out;
          animation: shine-lines 3s infinite ease-out;
}

.skeleton-container-wide .line ~ .line {
  background-color: #f9f9f9;
}

.skeleton-container-wide .clear {
  clear: left;
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 500px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 500px;
  }
}

