/* banner wrapper */
.heroWrap-loader,
.bannerWrap-loader {
  position: relative;
  background-color: #efefef;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerBlack h1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #000;
  z-index: 10;
  max-width: 70%;
}
.banner h1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  text-align: left;
  color: #fff;
  z-index: 10;
  max-width: 70%;
}
section.banner,
section.banner h1 {
  margin: 0 auto;
  width: 100%;
}
.heroWrap,
.bannerWrap,
section.banner {
  min-height: 366px;
}

.heroWrapHome {
  min-height: 400px;
}
.heroWrapHome {
  position: relative;
  /* background-color: rgb(0, 0, 0); */
  /* background-color: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(121, 9, 9, 1) 13%
  ); */
  /* background-image: linear-gradient(blue, pink); */
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.heroWrap,
.bannerWrap {
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}
.bannerWrap:after {
  content: "";
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.hero h1 {
  width: 38%;
  padding-top: 6rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
  color: black;
}
.hero h3,
.hero h6 {
  width: 38%;
  padding-top: 10px;
  font-size: 1.5rem;
  text-align: center;
  color: black;
}
.hero .btnWrap {
  width: 38%;
}
section.hero {
  margin: 0 auto;
  width: 100%;
}
section.size1 h1 {
  font-size: 48px !important;
}
section.size2 h1 {
  font-size: 40px !important;
}
section.size3 h1 {
  font-size: 32px !important;
}
section.size4 h1 {
  font-size: 52px !important;
}
section.size5 h1 {
  font-size: 44px !important;
}
section.size6 h1 {
  font-size: 36px !important;
}
section.size1 h6 {
  font-size: 24px !important;
}
section.size2 h6 {
  font-size: 20px !important;
}
section.size3 h6 {
  font-size: 16px !important;
}
section.size4 h6 {
  font-size: 28px !important;
}
.btnWrap {
  padding: 1rem 0;
  text-align: center;
}
